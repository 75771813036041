import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TitleType } from '../../../interface/title-type';
import { TitleTypeService } from '../../../service/title-type.service';
import { SnackbarService } from '../../../service/snackbar.service';
import { LayoutService } from '../../../service/layout.service';
import { ObjectId } from 'src/app/shared/type-aliases/object-id';

@Component({
  selector: 'app-title-type-edit',
  templateUrl: './title-type-edit.component.html',
  styleUrl: './title-type-edit.component.scss'
})
export class TitleTypeEditComponent implements OnInit {

  form: FormGroup = new FormGroup({
    _id: new FormControl<ObjectId>(undefined),
    description: new FormControl<string>(''),
    isPay: new FormControl<boolean>(false),
    typeForAdvance: new FormControl<ObjectId>(undefined)
  });
  filteredTypes: TitleType[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: {
      titleType: TitleType | undefined
      allTitleTypes: TitleType[]
    },
    private dialogRef: MatDialogRef<TitleTypeEditComponent>,
    private titleTypeService: TitleTypeService,
    private snackBar: SnackbarService,
    private layout: LayoutService
  ) { }


  ngOnInit() {
    this.buildForm();
    this.form.get('isPay').valueChanges.subscribe((isPay) => {
      this.filteredTypes = this.data.allTitleTypes.filter(tp => tp.isPay !== isPay);
    });
  }


  buildForm() {
    if (this.data.titleType) {
      this.form.patchValue(this.data.titleType)
      this.filteredTypes = this.data.allTitleTypes.filter(tp => tp.isPay !== this.data.titleType.isPay);
    } else {
      this.filteredTypes = this.data.allTitleTypes.filter(tp => tp.isPay);
    }
  }

  async onSubmit() {
    const isNew = Boolean(!this.form.get('_id').value);
    try {
      this.layout.loader = true;
      let newTitle = this.form.getRawValue() as TitleType;
      const id = await this.titleTypeService[isNew ? 'register' : 'update'](newTitle);
      newTitle["_id"] = id;
      this.snackBar.success(isNew ? 'TITLE_TYPE.ADDED' : 'TITLE_TYPE.UPDATED');
      this.dialogRef.close(newTitle)
    } catch (error) {
      this.snackBar.error(isNew ? 'TITLE_TYPE.NOT_ADDED' : 'TITLE_TYPE.NOT_UPDATED', null, error);
      console.error(`Erro ao registrar titleType\n\n`, error);
    } finally {
      this.layout.loader = false;
    }
  }

  close() {
    this.dialogRef.close()
  }

}

